.game-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  color: rgba(255,255,255,.8);
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent, #fff, transparent);
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .2;
  }

  &__slogan {
    font-weight: 100;
    letter-spacing: 3px;
    line-height: 107%;
    font-size: 11px;
    @media (max-width: 365px) {
      font-size: 10px;
      letter-spacing: 1.7px;
    }
  }

  &__logo {
    display: flex;
    justify-content: start;
    & > svg {
      height: 48px;
      width: auto;
      translate: 5% 0;
      @media (max-width: 365px) {
        height: 32px;
      }
    }
  }

  &__profile {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    letter-spacing: 0px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Nippo', sans-serif;
    @media (max-width: 365px) {
      font-size: 12px;
    }

    &--avatar {
      position: relative;
      span {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        font-size: 10px;
        background: #EA3498;
        color: #fff;
        padding: 0 4px;
        text-align: center;
        border-radius: 50%;
        //font-family: sans-serif;
        letter-spacing: -.5px;
        line-height: 100%;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 0px 2px #07000c;
      }
      .CircularProgressbar {
        width: 56px;
        min-width: 56px;
        height: 56px;
        float: left;
        @media (max-width: 365px) {
          width: 32px;
          min-width: 32px;
          height: 32px;
        }
      }
      .CircularProgressbar-trail {
        stroke: #212C64;
        stroke-width: 2px;
      }
      .CircularProgressbar-path {
        stroke: url(#defaultGradient);
        stroke-width: 2px;
      }
      & > img {
        width: 42px;
        min-width: 42px;
        height: 42px;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 50%;
        @media (max-width: 365px) {
          width: 24px;
          min-width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.game-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  position: relative;

  @media (max-width: 365px) {
    display: none;
  }

  &__block {
    background: linear-gradient(90deg, rgba(210, 70, 159, 0.1), rgba(75, 213, 206, 0.1));
    border-radius: 8px;
    position: relative;

    &--title {
      font-size: 13px;
      letter-spacing: .6px;
      margin-bottom: 3px;
      padding: 6px 12px 3px;
      position: relative;

      &:after {
        content: '';
        width: 60px;
        height: 1px;
        background: rgba(210, 70, 159, 0.2);
        position: absolute;
        bottom: 0;
        left: 12px;
      }
    }

    &--value {
      font-size: 14px;
      letter-spacing: .6px;
      padding: 0px 12px 8px;
      position: relative;
      overflow: hidden;
      border-radius: 0 0 8px 8px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, rgba(210, 70, 159, 0.8), rgba(75, 213, 206, 0.8));
      }
    }
  }
}

.game-balance {
  font-size: 42px;
  letter-spacing: .5px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;

  & > svg {
    width: 64px;
    height: 64px;
  }

  @media (max-width: 365px) {
    padding: 0px 0 10px;
    font-size: 32px;

    & > svg {
      width: 44px;
      height: 44px;
    }
  }
}

.game-button-holder {
  display: flex;
  flex-direction: column;
  max-width: 440px;
  height: auto;
  aspect-ratio: 1/1;
  margin: auto;
}

.game-button {
  overflow: hidden;
  max-width: 440px;
  height: auto;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: url('../assets/img/button.webp') center no-repeat #fff;
  background-size: contain !important;
  box-shadow: 
    inset 0px 0px 20px rgba(255, 55, 128, 0.3),
    0px 10px 40px #FF3780,
    0px 5px 15px #FF3780;
  border: none;
  background-color: transparent;
  font-size: 0;
  cursor: pointer;
  transition: .1s;
  margin: auto;

  & > img {
    width: 60%;
    height: auto;
    pointer-events: none;
    filter: drop-shadow(0px 0px 30px transparent);
    transition: .1s;
  }

  &:active {
    scale: 0.99;
    rotate: 5deg;

    & > img {
      filter: drop-shadow(0px 0px 30px #ff0040);
    }
  }
}

.game-energy {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 20px;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 365px) {
    font-size: 14px;
    span {
      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.game-level {
  margin: 10px 0 10px;
  &__info {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    letter-spacing: .6px;

    @media (max-width: 365px) {
      font-size: 10px;
    }

    &--current {
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &--numbers {
      font-weight: 300;
      b {
        font-weight: 500;
      }
    }
  }
}

.game-level__progress {
  background: #2e081b;
  padding: 4px;
  border-radius: 50px;
  margin-top: 10px;

  &--bar {
    border-radius: 50px;
    background: linear-gradient(90deg, #4BD5CF, #FF3780);
    position: relative;
    height: 4px;
    transition: .2s;

    &:before {
      content: '';
      border-radius: 50px;
      position: absolute;
      left: 0;
      width: 100%;
      background: linear-gradient(90deg, #4BD5CF, #FF3780);
      height: 100%;
    }

    span {
      position: absolute;
      top: 50%;
      right: 0;
      translate: 50% -50%;
      background: linear-gradient(90deg, #EA3498, #A6075E);
      min-width: 24px;
      font-weight: 600;
      border-radius: 6px;
      text-align: center;
      line-height: 100%;
      padding: 2px 7px 2px;
      font-size: 14px;
      box-shadow: 0px 2px 10px #A6075E;
    }
  }
}

.game-menu-outer {
  position: relative;
  z-index: 10;
}

.game-menu {
  display: flex;
  gap: 5px;
  background: linear-gradient(90deg, rgba(27, 0, 10, 0.8), rgba(9, 19, 18, 0.8));
  padding: 4px;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;
  bottom: 20px;
  margin: 0 5px;

  &.--margin-top {
    margin-top: auto;
  }

  & > a {
    padding: 15px 5px 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: rgba(255,255,255,.07);
    font-size: 10px;
    font-weight: 500;
    gap: 4px;
    color: rgba(255,255,255,.8);
    text-decoration: none;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      height: calc(100% - 7px);
      width: calc(100% - 7px);
      border-radius: 10px;
      box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,.1);
    }

    & > svg {
      width: auto;
      height: 14px;
    }

    &.active {
      background: linear-gradient(90deg, #19E1F2, #6CE7DD) !important;
      box-shadow: 0px 5px 25px rgba(25, 224, 242, 0.6);
      color: #1A224B;
      position: relative;

      span {
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, transparent, #6CE7DD, transparent);
          position: absolute;
          top: -4px;
          left: 0;
          opacity: .2;
        }
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: linear-gradient(90deg, transparent, #6CE7DD, transparent);
          position: absolute;
          bottom: -4px;
          left: 0;
          opacity: .2;
        }
      }
    }
  }

  &__play {
    background: linear-gradient(90deg, #EA3498, #A6075E) !important;
    z-index: 10;

    span {
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, transparent, #EA3498, transparent);
        position: absolute;
        top: -4px;
        left: 0;
        opacity: .2;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, transparent, #EA3498, transparent);
        position: absolute;
        bottom: -4px;
        left: 0;
        opacity: .2;
      }
    }
  }
}

.stats-badge {
  position: absolute;
  top: 0;
  right: 0;
  translate: 10% -15%;
  background: black;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;

  &.--green {
    background: linear-gradient(90deg, #51C81C, #48AF1B);
  }
  &.--red {
    background: linear-gradient(90deg, #FC381D, #DB2F18);
  }
}

.popup-digit {
  position: absolute;
  z-index: 10000;
  pointer-events: none;
  color: #fff;
  animation: popup-digit ease-out 1s;
  font-size: 24px;
  font-weight: 600;
}

@keyframes popup-digit {
  0% { transform: translateY(0%); opacity: 1 }
  100% { transform: translateY(-200%); opacity: 0 }
}