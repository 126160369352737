* {
  box-sizing: border-box;
  outline: none;

  -webkit-tap-highlight-color: transparent !important;
  -webkit-appearance: none;
  appearance: none;
  
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Nippo', sans-serif;
  background: #130101;
  color: #FFFFFF;
}

body {
  max-width: 480px;
  margin: 0 auto;
  box-shadow: 0px 0px 50px black;
  background: url('../assets/img/bg.jpg') center no-repeat;
  background-size: cover;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: auto;
}

.app-holder {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  height: 100dvh;
  // padding: 0 5px 20px;

  &__inset {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 20px 15px 85px;
    margin-bottom: -65px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: -1;
    background: linear-gradient(0deg, transparent, #130101);
  }
}

.--shadows-sm {
  text-shadow: -1px 0px 0px rgba(210, 69, 158, 0.8), 1px 1px 0px rgba(23, 228, 243, 0.8);
}

.--shadows-md {
  text-shadow: -2px -1px 0px rgba(210, 69, 158, 0.8), 2px 2px 0px rgba(23, 228, 243, 0.8);
}

.--shadows-lg {
  text-shadow: -4px -2px 0px rgba(210, 69, 158, 0.8), 4px 4px 0px rgba(23, 228, 243, 0.8);
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #130101;
  z-index: 1000000;
  flex-direction: column;
}

.access-denied {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #130101;
  z-index: 1000000;
}

.animate-part {
  display: flex;
  flex-direction: column;
}

.game-selector {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;

  &__item {
    background: rgba(255,255,255,.2);
    padding: 20px;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
  }
}

.race-canvas {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 480px;
  width: 100%;
  height: 100vh;
  z-index: 10000;
}

.race-canvas canvas {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100vh !important;
}