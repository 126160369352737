.store-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px 15px;

  @media (max-width: 390px) {
    gap: 10px 10px;
  }

  @media (max-width: 365px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.store-preloader {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px 15px;

  @media (max-width: 390px) {
    gap: 10px 10px;
  }

  @media (max-width: 365px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > div {
    background: rgba(255,255,255,.07);
    height: 115px;
    width: 100%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, rgba(255,255,255), transparent);
      opacity: .1;
      animation: storePreloader 1s infinite ease-in-out;
      translate: -100% 0;

      @keyframes storePreloader {
        0% {translate: -100% 0;}
        100% {translate: 300% 0;}
      }
    }
  }
}

.store-card {
  background: rgb(32 16 26);
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  height: 115px;
  position: relative;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.1);
    z-index: 3;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
  }

  &.--not-available {
    pointer-events: none;
    opacity: .6;
  }

  &.--bougth {
    background: rgb(20, 32, 26);
    pointer-events: none;
    
    .store-card__picture {
      svg {
        color: var(--glow);
        filter: drop-shadow(0px 0px 10px var(--glow));
      }
    }

    .store-card__bottom {
      border-color: rgba(0, 255, 85, 0.2);
      span {
        border-color: rgba(0, 255, 85, 0.2);
      }
    }
  }

  &__top {
    display: flex;
    gap: 15px;
  }

  &__picture {
    width: 42px;
    min-width: 42px;
    height: 42px;
    padding: 3px;
    border-radius: 50%;
    z-index: 2;
    position: relative;
    margin-top: 5px;

    &--lock {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0,0,0,.4);

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &--check {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0,0,0,.4);

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      mix-blend-mode: hard-light;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      border: 1px solid #fff;
      box-shadow: 
        inset 0px 0px 10px var(--glow),
        inset 0px 0px 2px #fff,
        inset 0px 0px 2px var(--glow),
        0px 0px 10px var(--glow),
        0px 0px 2px #fff;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__info {
    &--name {
      font-size: 14px;
      font-weight: 500;
    }
    &--income {
      font-size: 13px;

      small {
        color: #fff;
        opacity: .6;
        display: block;
      }

      span {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        margin-top: 5px;

        svg {
          height: 16px;
          width: 16px;
          min-width: 16px;
          margin: -12px 0;
        }
      }
    }
  }

  &__bottom {
    font-size: 13px;
    padding-top: 10px;
    margin-top: 5px;
    border-top: 1px solid rgba(234, 52, 152, 0.2);
    display: flex;
    align-items: center;

    span {
      border-left: 1px solid rgba(234, 52, 152, 0.2);
      padding-left: 10px;
      margin-left: 10px;
      display: inline-flex;
      align-items: center;
      gap: 5px;

      svg {
        height: 16px;
        width: 16px;
        min-width: 16px;
        margin: -12px 0;
      }
    }
  }
}


.spinner {
  animation: spinner 2s cubic-bezier(0.36,.6,.31,1) infinite;
  &__1 {
    animation-delay:-.5s
  }
  &__2 {
    animation-delay:-1s
  }
  &__3 {
    animation-delay:-1.5s
  }
  @keyframes spinner {
    0% {r:0}
    25% {r:3px; cx:4px}
    50% {r:3px; cx:12px}
    75% {r:3px; cx:20px}
    100% {r:0; cx:20px}
  }
}